<template>
  <div class="mb-3">
    <h-card class="card-hover shadow-sm">
      <b-row class="align-items-center">
        <b-col cols="6" class="d-flex justify-content-between">
          <div>
            <b>{{ messageComp.name }}</b>
          </div>
        </b-col>
        <b-col class="d-flex "
          >{{ $t('messages.createdOn') }}
          {{
            $moment(messageComp.creation)
              .lang($i18n.locale)
              .format('LL')
          }}</b-col
        >
        <b-col
          cols="auto"
          class="d-flex align-items-center justify-content-end"
        >
          <h-button transp grey @click="displaySchedulesFn()">
            <b-icon
              :icon="displaySchedules ? 'chevron-up' : 'chevron-down'"
              class="w-15px mr-2"
              font-scale="1"
            ></b-icon>
            {{
              messageComp.scheduleId.length > 1
                ? $t('messages.seeSchedules')
                : $t('messages.seeSchedule')
            }}
          </h-button>
        </b-col>
      </b-row>

      <b-collapse
        v-model="displaySchedules"
        :id="`message-schedules-${index}`"
        class="mt-2"
      >
        <template v-if="scheduleLoading">
          <vue-content-loading :width="1070" :height="45">
            <rect x="2" y="6" rx="1" ry="1" width="300" height="25" />
          </vue-content-loading>
        </template>
        <template v-else-if="messageSchedules && messageSchedules.length > 0">
          <b-row v-for="(schedule, index) in messageSchedules" :key="index">
            <b-col>
              {{ formatDate(schedule.startDate, schedule.endDate) }}
            </b-col>
            <b-col
              cols="auto"
              class="d-flex align-items-center justify-content-end"
            >
              <h-button
                flat
                orange
                :disabled="scheduleLoading"
                @click="editSchedule(messageSchedules[index].scheduleId)"
              >
                <b-icon
                  icon="pencil"
                  class="w-15px mr-2"
                  font-scale="1"
                ></b-icon>
                {{ $t('messages.editSchedule') }}
              </h-button>
              <h-button
                :disabled="scheduleLoading"
                flat
                red
                @click="deleteItem(messageSchedules[index])"
                class="ml-2"
                ><b-icon class="w-15px mr-2" icon="trash"></b-icon>
                {{ $t('actions.delete') }}</h-button
              >
            </b-col>
          </b-row>
        </template>
      </b-collapse>
    </h-card>
  </div>
</template>

<script>
import messageService from '@/services/messageService.js'

import VueContentLoading from 'vue-content-loading'
export default {
  data() {
    return {
      displaySchedules: false,
      messageSchedules: [],
      scheduleLoading: false,
    }
  },
  components: {
    VueContentLoading,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
  computed: {
    messageComp() {
      return this.message
    },
  },
  methods: {
    async displaySchedulesFn() {
      this.displaySchedules = !this.displaySchedules
      if (this.messageSchedules.length > 0) return
      this.scheduleLoading = true

      this.messageComp.scheduleId.forEach(async (id) => {
        const scheduleDetail = await messageService.getFullSchedule(id)
        this.messageSchedules.push(scheduleDetail.data)

        this.scheduleLoading = false
      })
    },
    deleteItem(schedule) {
      this.$emit('deleteMessage', schedule)
    },
    editSchedule(id) {
      this.$router.push({ name: 'schedule-edition', params: { id: id } })
    },
    formatDate(startDate, endDate) {
      const START_YEAR_MIN = '0001'
      const END_YEAR_NO_END = ['2060', '2099']

      const startYear = this.$moment(startDate).format('YYYY')
      const endYear = this.$moment(endDate).format('YYYY')

      let startLabel =
        startYear === START_YEAR_MIN ? '' : this.$t('messages.from')
      if (startYear !== START_YEAR_MIN && END_YEAR_NO_END.includes(endYear))
        startLabel = this.$t('messages.startingFrom')
      let endLabel = ''

      if (
        startYear === START_YEAR_MIN &&
        END_YEAR_NO_END.includes(endYear) &&
        endYear !== START_YEAR_MIN
      ) {
        return this.$t('messages.plannedNoLimit')
      } else if (!END_YEAR_NO_END.includes(endYear)) {
        endLabel = ' au '
      }

      const startFormatted = this.$moment(startDate)
        .lang('fr')
        .format('LL')
      const endFormatted = END_YEAR_NO_END.includes(endYear)
        ? ''
        : this.$moment(endDate)
            .lang('fr')
            .format('LL')

      const scheduleDatesFormatted = `${startLabel ? startLabel : ''}${
        startLabel ? startFormatted : ''
      }${endLabel}${endFormatted}`

      return scheduleDatesFormatted
    },
  },
}
</script>

<style lang="scss" scoped>
.card-hover:hover {
  -webkit-box-shadow: 0 0.25rem 0.4rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.3rem 0.5rem rgba(0, 0, 0, 0.075) !important;
}
h5 {
  line-height: 35px;
}
@media screen and (max-width: 767.5px) {
  .icons {
    padding: 0;
    flex-direction: column;
    align-items: center;
    button {
      padding: 4px 6px;
    }
    button:last-child {
      margin-top: 3px;
      margin-left: 0 !important;
    }
  }
}
</style>
