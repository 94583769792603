<template>
  <b-container class="pt-3">
    <MessagesFilters @filter="getMessagesFilter" @search="filterMessages" />
    <b-row class="my-4">
      <b-col cols="12">
        <template
          v-if="messagesLoaded && messagesLocal && messagesLocal.length > 0"
        >
          <transition-group name="list" tag="p">
            <MessageItem
              v-for="(message, index) in messagesLocal"
              :message="message"
              :key="index"
              :index="index"
              @deleteMessage="deleteMessageItem"
            />
          </transition-group>
          <b-modal ref="confirm-delete-modal" hide-header hide-footer>
            <div class="d-block text-center">
              <h5>
                Êtes-vous sûr de vouloir supprimer définitivement cette
                planification lié au message
                <b>{{
                  currentScheduleToDelete ? currentScheduleToDelete.title : ''
                }}</b>
                ? <br />Le message ne sera plus diffusé.
              </h5>
            </div>
            <div class="d-flex mt-3 ">
              <h-button
                @click="hideModal"
                outline
                grey
                class="ml-auto px-3 mr-3"
              >
                {{ $t('actions.no') }}
              </h-button>
              <h-button class="px-3" red @click="deleteItem()">
                {{ $t('actions.yes') }}
              </h-button>
            </div>
          </b-modal>
        </template>
        <template
          v-else-if="
            messagesLoaded && messagesLocal && messagesLocal.length === 0
          "
        >
          <h-card class="w-100">
            <b-row class="justify-content-center">
              <b-col
                cols="12"
                lg="5"
                md="8"
                sm="10"
                class="d-flex flex-column align-items-center"
              >
                <b-img
                  :src="require('@/assets/svg/no_data_schedule.svg')"
                  alt="radioshop logo"
                  class="w-75 my-4"
                ></b-img>
                <h5 class="mt-4 text-center">
                  {{ $t('messages.emptyMessage') }}
                </h5>
              </b-col>
            </b-row>
          </h-card>
        </template>
        <template v-else>
          <ScheduleItemSkeleton v-for="i in 10" :key="i" />
        </template>
      </b-col>
    </b-row>

    <HScrollTopBtn :scrollTopBtn="scrollTopBtn" />
  </b-container>
</template>

<script>
import MessageItem from '@/components/message/MessageItem'
import MessagesFilters from '@/components/message/MessagesFilters'
import ScheduleItemSkeleton from '@/components/skeleton/ScheduleItemSkeleton'
import messageService from '@/services/messageService.js'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      scrollTopBtn: false,
      endOfList: false,
      messagesLocal: [],
      messagesLoaded: false,
      busy: false,
      pageNumber: 0,
      totalMessages: null,
      filterParams: null,
      currentScheduleToDelete: null,
    }
  },
  components: {
    MessagesFilters,
    MessageItem,
    ScheduleItemSkeleton,
  },
  created() {
    this.getMessages()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    async storeSelectedSiteId() {
      this.getMessages()
    },
  },
  methods: {
    handleScroll() {
      if (document.documentElement.scrollTop > 500) this.scrollTopBtn = true
    },
    filterMessages(search) {
      this.pageNumber = 0
      const results = this.messages.filter((message) =>
        message.name.toLowerCase().includes(search)
      )
      this.messagesLocal = results
    },
    getMessagesFilter(params) {
      this.filterParams = params
      this.pageNumber = 0
      this.getMessages(params)
    },
    async getMessages() {
      this.messagesLoaded = false
      await this.$store.dispatch('message/_getMessages', {
        status: this.filterParams ? this.filterParams : null,
        pageNumber: this.pageNumber,
      })
      this.totalMessages =
        this.messages.length && this.messages[0].totalRows
          ? this.messages[0].totalRows
          : 0
      this.messagesLocal = [...this.messages]

      this.messagesLoaded = true
      this.busy = false
    },
    deleteMessageItem(schedule) {
      this.currentScheduleToDelete = schedule
      this.showModal()
    },
    showModal() {
      this.$refs['confirm-delete-modal'].show()
    },
    hideModal() {
      this.$refs['confirm-delete-modal'].hide()
    },
    async deleteItem() {
      try {
        const res = await messageService.deleteSchedule(
          this.currentScheduleToDelete.scheduleId
        )
        if (res && res.data) {
          this.$refs['confirm-delete-modal'].hide()
          this.$toast.success(
            `La planification lié au message ${this.currentScheduleToDelete?.title} a été supprimée`
          )
          this.getMessages()
          this.currentScheduleToDelete = null
        }
      } catch (e) {
        this.$refs['confirm-delete-modal'].hide()
        this.$toast.error("Une erreur s'est produite. Veuillez réessayer.")
      }
    },
  },
  computed: {
    storeSelectedSiteId() {
      return this.$store.state.user?.selectedSite?.id
    },
    ...mapState({
      messages: (state) => state.message.mediaMessages,
    }),
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.4s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
